.orderPhotoTape {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;

    white-space: nowrap;
    padding: 10px;
    max-width: 100%;
    border: 5px solid #282828;
    height: 120px;
    background-color: #282828;
    position: sticky;
    top: 0;
    z-index: 1;

    padding: 10px;
}


.orderPhotoTape::-webkit-scrollbar {
    height: 6px;
}

.orderPhotoTape::-webkit-scrollbar-thumb {
    background-color: #7b7b7b;
    border-radius: 4px;
}

.orderPhotoTape::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.orderPhotoTape::-webkit-scrollbar-track {
    background-color: #282828;
}

.draggable-image {
    display: inline-block;
    margin-right: 10px;
    cursor: grab;
}

.draggable-image img {
    max-height: 120px;
    object-fit: cover;
}