.innerCardItemBlockRetangle {
    position: relative;
    width: 100%;
    height: 145px;
    border: 2px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: white;
}

.dropped-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    cursor: pointer;
}