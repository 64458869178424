.innerCardItemBlockName {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    margin: 0;
    width: 100%;
}

.innerCardItemBlockButtons {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.innerCardItemBlockButton {
    width: 35px;
    height: 30px;
    background: unset;
    background-repeat: no-repeat;
    background-position: bottom;
    cursor: pointer;
    border: none;
}

.buttonCopy {
    background-image: url("../../../../public/svg/orderButton1.svg");
}

.buttonCopyAll {
    background-image: url("../../../../public/svg/orderButton2.svg");
}

.buttonDelete {
    background-image: url("../../../../public/svg/orderButton3.svg");
}

.buttonZoom {
    background-image: url("../../../../public/svg/orderButton4.svg");
}

.buttonCopy:hover {
    background-image: url("../../../../public/svg/orderButton1Hover.svg");
    color: red;
}

.buttonCopyAll:hover {
    background-image: url("../../../../public/svg/orderButton2Hover.svg");
}

.buttonDelete:hover {
    background-image: url("../../../../public/svg/orderButton3Hover.svg");
}

.buttonZoom:hover {
    background-image: url("../../../../public/svg/orderButton4Hover.svg");
}

.disabledButton {
    opacity: 0.3;
    cursor: not-allowed;
}

.disabledButton.buttonCopy:hover {
    background-image: url("../../../../public/svg/orderButton1.svg");
}

.disabledButton.buttonCopyAll:hover {
    background-image: url("../../../../public/svg/orderButton2.svg");
}