.calculatorHeader {
    width: calc(100% - 20px);
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 45px;
    margin-bottom: 30px;
    border: solid 1px rgba(49, 69, 87, 0.5);
    border-radius: 10px;
    display: flex;
}

.calculatorHeaderLeft {
    width: calc((100% - 10px)/3);
    min-width: 235px;
    height: 282px;
    border-right: solid 1px rgba(49, 69, 87, 0.5);
    ;
    padding-top: 18px;
    padding-left: 20px;
}

.calculatorHeaderLeftRadio,
.calculatorHeaderCenterRadio {
    display: block;
    width: 100%;
    margin-top: 5px;
}

.calculatorHeaderLeftRadio input,
.calculatorHeaderCenterRadio input {
    visibility: hidden;
    position: absolute;
}

.calculatorHeaderLeftRadio input+p {
    cursor: pointer;
    border: 2px solid transparent;
    padding-left: 17px;
}

.calculatorHeaderLeftRadio p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #929BA3;
    margin: 0;
}

.calculatorHeaderLeftRadio input:checked+p,
.calculatorHeaderCenterRadio input:checked+p {
    background-color: rgba(49, 69, 87, 0.1);
}

.calculatorHeaderLeftRadioTextChecked {
    font-weight: 500 !important;
    color: #A94991 !important;
}

.calculatorHeaderCenter {
    width: calc((100% - 10px)/3);
    height: 282px;
    border-right: solid 1px rgba(49, 69, 87, 0.5);
    ;
    padding-top: 18px;
}


.calculatorHeaderCenterText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #314557;
    padding-left: 17px;
    border: 2px solid transparent;
    margin-top: 5px;
    margin-bottom: 0;
}

.calculatorHeaderCenterText:hover {
    cursor: pointer;
}

.calculatorHeaderCenterTextSelect {
    margin-right: 20px;
    background-color: rgba(49, 69, 87, 0.1);
}

.innerCardItemRange {
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.innerCardItemRange:last-child {
    margin-bottom: 30px;
}

.innerCardItemRangeText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #314557;
    width: 25%;
}

.rangeWrapper {
    width: 75%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.rangeLabelAmount {
    display: block;
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    width: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: solid 1.5px #848484;
    border-radius: 10px;
    margin-right: 30px;
}

.rangeAmount {
    margin: 0;
    -webkit-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    max-width: 600px;
    height: 2px;
    background-color: #929292;
    box-shadow: 1px 4px 7px rgba(0, 0, 0, 0.06);
}

.rangeAmount::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 4px solid #FFFFFF;
    background: #591E4A;
    cursor: pointer;
}

.rangeAmount::-moz-range-thumb {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 4px solid #FFFFFF;
    background: #591E4A;
    cursor: pointer;
}

.calculatorHeaderRight {
    width: calc((100% - 10px)/3);
    min-width: 325px;
    display: flex;
    flex-wrap: wrap;
}

.calculatorHeaderRightText {
    margin: 10px 20px 5px 20px;
}

.calculatorHeaderRightText p {
    margin: 2px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #314557;
}

.calculatorHeaderRightDowland {
    margin: 5px 20px;
    width: 100%;
    border: 1px solid #591E4A;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.calculatorHeaderRightDowland p {
    margin: 5px;
    width: 100%;
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.calculatorHeaderRightDowland a {
    margin: 5px 0;
    text-align: center;
    width: 50%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #A94991;
    text-decoration: none;
    transition: 300ms;
}

.calculatorHeaderRightDowland a:hover {
    text-decoration: underline;
}

.calculatorHeaderRightButtons {
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.calculatorHeaderRightButton {
    padding: 5px 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    border: 1px solid #314557;
    background: #314557;
    border-radius: 31px;
    transition: 300ms;
    cursor: pointer;
}

.calculatorHeaderRightButton:hover {
    background: #FFFFFF;
    color: #314557;
    border: #314557 1px solid;
}

.calculatorHeaderRightPrices {
    width: 100%;
    display: flex;
    margin-top: 10px;
}

.calculatorHeaderRightPriceBook {
    width: 50%;
    border-top: 1px solid rgba(49, 69, 87, 0.5);
    border-right: 1px solid rgba(49, 69, 87, 0.5);
}

.calculatorHeaderRightPriceBook p {
    margin: 0;
    width: 100%;
    text-align: center;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #314557;
}

.calculatorHeaderRightPriceTotal {
    width: 50%;
    border-top: 1px solid rgba(49, 69, 87, 0.5);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.calculatorHeaderRightPriceTotal p {
    margin: 0;
    width: 100%;
    text-align: center;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #314557;
}

.calculatorHeaderRightPrice {
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    color: #591E4A;
}


.innerCard {
    margin: 30px 10px 0px 10px;
    width: 100%;
}

.innerCardItem {
    margin-top: 30px;
}

.innerCardItemTitle {
    position: relative;
    height: 30px;
    width: 287px;
    background-color: rgba(89, 30, 74, 0.1);
    display: flex;
    justify-content: center;
    margin-left: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.innerCardItemTitle span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
}

.innerCardItemContent {
    width: 100%;
    background-color: rgba(89, 30, 74, 0.1);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    border-top-right-radius: 0;
    margin-bottom: 30px;
}

.innerCardItemBlockTitleWrapper {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
}

.innerCardItemBlockTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    margin: 0;
    width: 100%;
}

.innerCardItemBlock {
    max-width: 210px;
    width: calc(25% - 60px);
    margin: 30px 30px;
}


.innerCardItemBlock:nth-child(n+6) {
    margin-top: 20px;
}

.innerCardItemBlockSquare {
    cursor: pointer;
    width: 100%;
    padding-top: 100%;
    border-radius: 14px;
    background-color: #282828;
    filter: grayscale(100%) blur(2px);
    background-size: cover;
    background-position: center;
    transition: filter 0.3s ease, box-shadow 0.3s ease;
}

.innerCardItemBlockSquare:hover {
    filter: grayscale(0%) blur(0px);
    box-shadow: 0px 0px 20px #561c49;
}

.calcUploadButton {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}

.calcBottomButton {
    padding: 20px 35px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #FFFFFF;
    background: #591E4A;
    border-radius: 31px;
    border: 1px solid #591E4A;
    transition: 300ms;
}

.calcBottomButton:hover {
    background: #FFFFFF;
    color: #591E4A;
    border: #591E4A 1px solid;
    cursor: pointer;
}

.calcNavigButtons {
    margin: 30px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.calcNavigButton {
    margin-left: 40px;
    margin-right: 40px;
    padding: 10px 25px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    text-align: center;
    color: #FFFFFF;
    background: #314557;
    border-radius: 31px;
    border: 1px solid #314557;
    transition: 300ms;
}

.calcNavigButton:hover {
    background: #FFFFFF;
    color: #314557;
    border: #314557 1px solid;
    cursor: pointer;
}


.hideCardItem {
    display: none;
}