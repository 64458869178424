.contentCards {
    width: 100%;
    margin: 40px;
    display: flex;
    flex-wrap: wrap;
}

.contentCardsLine {
    display: flex;
    justify-content: center;
    width: 100%;
}

.contentCardsLine:nth-child(n+2) {
    margin-top: 45px;
}

.contentCard {
    position: relative;
    margin-bottom: 40px;
    width: 250px;
    padding: 70px 10px;
    border-radius: 20px;
    margin-left: 50px;
    margin-right: 50px;
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;
}

.backgroundImage {
    background-color: #282828;
    z-index: -1;
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: grayscale(100%) blur(2px);
    background-size: cover;
    background-position: center;
    transition: filter 0.3s ease, box-shadow 0.3s ease;
}

.contentCard:hover .backgroundImage {
    filter: grayscale(0%) blur(0px);
    box-shadow: 0px 0px 20px #561c49;
}