.zoomed_image {
    max-width: 100%;
    max-height: 80vh;
    display: block;
    margin: 10px;
}

.modal_close_button {
    position: absolute;
    top: 2px;
    right: 2px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333; /* Цвет крестика по умолчанию */
}

.modal_close_button:hover .modal_close_icon {
    color: red; /* Цвет крестика при наведении */
}

.modal_close_icon {
    font-size: 34px;
    font-weight: bold;
    transition: color 0.3s ease;
}