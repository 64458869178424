.orderHeader {
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    background-color: #314557;
    height: 100px;
}

.orderHeaderList {
    width: 100%;
    list-style-type: none;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0;
    padding: 0;
}

.orderHeaderListName {
    width: calc(100% / 4);
    box-sizing: border-box;
    padding-top: 23.8px;
    padding-bottom: 23.8px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    cursor: pointer;
    text-align: center;
}

.orderHeaderListName a {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    text-decoration: none;
    text-align: center;
}

.orderHeaderListIcon a::after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 66px;
    height: 70px;
    background-image: url("../../../public/svg/basket.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 66px 70px;
    padding-left: 10px;
    padding-right: 10px;
}

.orderHeaderListName:hover {
    background-color: #435f78;
}


.orderHeaderListIcon a:hover::after {
    background-image: url("../../../public/svg/basketHover.svg");
}

.orderTotal {
    padding: 40px 10px;
    background-color: #314557;
    display: flex;
    width: 100%;
}

.orderTotal p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #FFFFFF;
    margin-top: 30px;
}

.orderTotalCenter {
    margin-left: 100px;
}

.orderTotalRight {
    margin-top: auto;
    margin-left: auto;
}

.orderTotalButtonCanel {
    width: 200px;
    height: 40px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    background: unset;
    cursor: pointer;
    border: none;
    color: #FFFFFF;
}

.orderTotalButtonCanel:hover {
    color: #cccccc;
}

.orderTotalButtonConfirm {
    width: 200px;
    height: 40px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    background-color: #C4C4C4;
    border: none;
    border-radius: 23.5px;
    cursor: pointer;
    color: #314557;
}

.orderTotalButtonConfirm:hover {
    background-color: #dedede;
}