.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.modal_content {
    background-color: white;
    padding: 20px;
    border: 2px solid #591e4a;
    border-radius: 5px;
}

.modal_content h2, 
.errorModal p{
    margin: 0;
}

.modal_buttonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
}

.modal_buttonContainer div {
    width: 75px;
    text-align: center;
    padding: 5px;
    background-color: #A94991;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal_buttonContainer div:hover {
    background-color: #843971;
}