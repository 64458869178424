
.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
}

.loader_text {
    font-size: 26px;
    margin-bottom: 30px;
}

.loader_circle {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #007bff;
    /* Цвет верхней границы */
    border-radius: 50%;
    width: 96px;
    height: 96px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}