.innerCardItemTitleClose {
    position: absolute;
    top: 0;
    right: 0;
    width: 28px;
    height: 28px;
    background: unset;
    background-repeat: no-repeat;
    background-position: bottom;
    cursor: pointer;
    border: none;
    background-image: url("../../../../public/svg/orderTitleButtonClose.svg");
}

.innerCardItemTitleClose:hover {
    background-image: url("../../../../public/svg/orderTitleButtonCloseHover.svg");
}

.innerCardOrderItemContent {
    width: 100%;
    background-color: rgba(89, 30, 74, 0.1);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 10px;
    border-top-right-radius: 0;
}