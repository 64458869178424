body {
  margin: 0px;
  font-family: Montserrat;
}

@font-face {
  font-family: Montserrat;
  src: url(../public/fonts/Montserrat-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: NotoSans;
  src: url(../public/fonts/NotoSans-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url(../public/fonts/Montserrat-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url(../public/fonts/Montserrat-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url(../public/fonts/Montserrat-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}