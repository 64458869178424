.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-body {
    margin: 20px;
    font-size: 16px;
    color: #333;
}

.modal_close_button {
    position: absolute;
    top: 2px;
    right: 2px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    /* Цвет крестика по умолчанию */
}

.modal_close_button:hover .modal_close_icon {
    color: red;
    /* Цвет крестика при наведении */
}