.footer {
    background-color: #591E4A;
}

.footerContent {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 20px);
    max-width: 1200px;
    padding: 25px 10px;
    display: flex;
    justify-content: space-evenly;
}

.footerTextTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: default;
}

.footerTextList {
    list-style-type: none;
    padding: 0;
    width: 90%;
}

.footerTextListName {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    text-decoration: none;
    margin-bottom: 4px;
}

.footerTextListName a {
    text-decoration: none;
    color: #FFFFFF;
}

.footerTextListName:hover a {
    color: rgba(243, 242, 242, 0.73);
}
