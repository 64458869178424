.main {
    min-height: calc(100vh - 108px - 330px);
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
  }
  