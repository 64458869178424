.lkEditForm {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}

.lkEditTitle {
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: rgba(49, 69, 87, 0.8);
}

.lkEditColumn {
    flex: 1;
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
}

.inputContainer {
    margin-bottom: 20px;
}

.inputLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: rgba(49, 69, 87, 0.8);
    ;
}

.lkEditFormInput {
    display: block;
    width: 100%;
    height: 28px;
    border: none;
    border-bottom: 2px solid #AFAFAF;
    margin-bottom: 6px;
    text-align: left;
}

.lkEditFormInput:focus-visible {
    outline: none;
}

.lkEditFormInput::placeholder {
    color: #b9b9b9;
    font-size: 13px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
}

.lkSendEmail {
    margin-top: auto;
    align-self: flex-end;
    background: #314557;
    border: 1px solid #314557;
    border-radius: 35px;
    color: #FFFFFF;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    height: 45px;
    margin-left: 20px;
    cursor: pointer;
    transition: 300ms;
}

.lkSendEmail:hover {
    color: #314557;
    background-color: #FFFFFF;
    border: #314557 1px solid;
}

.lkEditSave {
    margin-top: auto;
    align-self: flex-end;
    background: #314557;
    border: 1px solid #314557;
    border-radius: 35px;
    color: #FFFFFF;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    width: 160px;
    height: 45px;
    cursor: pointer;
    transition: 300ms;
}

.lkEditSave:hover {
    color: #314557;
    background-color: #FFFFFF;
    border: #314557 1px solid;
}