.wrapper_1200 {
    width: 1200px;
    margin: 100px auto;
}

.hidden {
    display: none;
}

.wrapper_2000 {
    width: 2000px;
    margin: 100px auto;
}

body {
    margin: 0;
    padding: 0;
}

.buttons {
    margin-top: 95px;
    display: flex;
    justify-content: space-evenly;
}

.headerButton {
    cursor: pointer;
    width: 170px;
    padding: 8px 0px;
    background: #D7D7D7;
    border-radius: 31px;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    color: #314557;
    text-decoration: none;
}

.headerButton:hover {
    background: #591E4A;
    color: #FFFFFF;
}

.headerButton_active {
    background: #66CC33;
    color: #FFFFFF;
}

.tablesTitle {
    margin-left: 30px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}

table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 14px;
    text-align: center;
}

table,
td,
th {
    box-sizing: border-box;
    border: 1px solid #000000;
    padding: 0;
}

td,
th {
    height: 25px;
    overflow: hidden;
    word-wrap: break-word;
}

#table1 {
    margin-top: 98px;
}

.tableButton {
    margin-top: 4px;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    padding: 0;
    border: none;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.tableButton_info {
    background-image: url('../../public/svg/info.svg');
}

.tableButton_orderHistory {
    background-image: url('../../public/svg/orderHistory.svg');
}

.tableButton_supportHistory {
    background-image: url('../../public/svg/supportHistory.svg');
}

.tableButton_notebook {
    background-image: url('../../public/svg/notebook.svg');
}

.tableButton_greenCheck {
    background-image: url('../../public/svg/greenCheck.svg');
}

.tableButton_blueCheck {
    background-image: url('../../public/svg/blueCheck.svg');
}

.tableButton_redButton {
    background-image: url('../../public/svg/redButton.svg');
}

.tableButton_greyDowland {
    background-image: url('../../public/svg/greyDowland.svg');
}

.tableButton_greenButton {
    background-image: url('../../public/svg/greenButton.svg');
}

.tableButton_blueCheck {
    background-image: url('../../public/svg/blueCheck.svg');
}

.tableButton_tg {
    background-image: url('../../public/svg/tg.svg');
}

.tableButton_wa {
    background-image: url('../../public/svg/wa.svg');
}

.tableButton_greenPlus {
    background-image: url('../../public/svg/greenPlus.svg');
}

.tableButton_redTrash {
    background-image: url('../../public/svg/redTrash.svg');
}

.mainTitle {
    margin: auto;
    margin-top: 70px;
    width: 1150px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
    background-color: #591E4A;
    padding: 15px 15px
}

#table2 {
    margin-top: 45px
}

#table3,
#table4,
#table5,
#table6,
#table7,
#table8,
#table9,
#table10,
#table11,
#table12,
#table13,
#table14 {
    margin-top: 98px;
}

.moneyText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-left: 30px;
}


.edit {
    border: 3px solid #334455;

}



.butons {
    margin: 0 15px 0 0;

    cursor: pointer;
    display: block;
    float: right;
    padding: 10px 15px;
    background: #507EB9;
    text-decoration: none;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-family: Arial;
    border: none;
}

.butons button:hover {
    background: #d6d6d7;
}

.butons a:hover {
    background: #d6d6d7;
}

.wrap {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
}

.modal {
    border: 1px solid #000;
    display: block;
    width: 400px;
    height: 100px;
    background: #ddd;
}

.modal input {
    margin: 15px 0 20px 20px;
    width: 360px;
}


.adminTableInput {
    font-family: 'Montserrat';
    font-size: 14px;
    text-align: center;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    padding: 0;
    margin: 0;
    border: 2px solid #000000;
    outline: none;
}

.addNewProductButton {
    background-color: #66CC33;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.addNewProductButton:hover {
    background-color: #51a327;
}

.modalAdmin {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalAdmin-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    height: 80%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.modalAdmin-content .closeAdmin {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #aaa;
    float: right;
    font-size: 34px;
    font-weight: bold;
}

.modalAdmin-content .closeAdmin:hover,
.modalAdmin-content .closeAdmin:focus {
    color: #f23127;
    text-decoration: none;
    cursor: pointer;
}

.modalAdmin-content h2 {
    margin-top: 0;
}

.modalAdmin-content textarea {
    flex-grow: 1;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    font-size: 16px;
    box-sizing: border-box;
    resize: none;
    border-radius: 5px;
}

.modalAdmin-content button {
    background-color: #66CC33;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s;
}

.modalAdmin-content button:hover {
    background-color: #51a327;
}

.modalAdmin-info {
    width: 100%;
    font-size: 20px;
    margin-bottom: 10px;
}

.modalAdmin-info span {
    font-size: 24px;
    font-weight: bold;
}

.modalAdmin input[type="text"] {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    width: 200px;
}

.modalAdmin-buttons{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.modalAdmin-buttons button{
    width: 45%;
}

.modalAdmin-buttons .redButton{
    background-color: #e40d0d;
}

.modalAdmin-buttons .redButton:hover{
    background-color: #ae0b0b;
}


.search-bar {
    display: flex;
    align-items: center;
}

.search-bar input[type="text"] {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    width: 200px;
}

.search-bar button {
    padding: 8px 16px;
    font-size: 16px;
    background-color: #66CC33;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.search-bar button:hover {
    background-color: #51a327;
}