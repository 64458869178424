.lkTopContent {
    background: rgba(89, 30, 74, 0.15);
    border-radius: 11px;
    display: flex;
    justify-content: center;
    margin: 25px;
}

.lkTop {
    max-width: 1200px;
    display: flex;
    justify-content: space-around;
    height: 400px;
}

.lkTopLeft {
    display: flex;
    width: 35%;
}

.lkTopButtons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.lkButton {
    background: #591E4A;
    border: none;
    border-radius: 35px;
    color: #FFFFFF;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    padding-left: 40px;
    padding-right: 40px;
    width: 215px;
    height: 60px;
    transition: 300ms;
}

.lkButton:hover {
    background: #FFFFFF;
    color: #591E4A;
    border: #591E4A 1px solid;
    cursor: pointer;
}

.lkTopRight {
    width: 60%;
    position: relative;
}


.lkTopLogOut button {
    position: absolute;
    top: 20px;
    right: 0px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #C4C4C4;
    border: none;
    background: none;
    transition: 300ms;
}

.lkTopLogOut button:hover {
    cursor: pointer;
    color: #591E4A
}

.lkTopUser {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.lkTopUser h2 {
    width: 100%;
    text-align: center;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: rgba(49, 69, 87, 0.8);
}

.lkTopUserText {
    width: calc(100% - 400px);
    margin-left: 25px;
}

.lkTopUser p {
    text-align: left;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: rgba(49, 69, 87, 0.8);
    margin: 5px;
}

.lkButtonEditUserInfo {
    background: #314557;
    border: 1px solid #314557;
    border-radius: 35px;
    color: #FFFFFF;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    height: 45px;
    cursor: pointer;
    transition: 300ms;
    width: 50%;
    margin-top: 15px;
}

.lkButtonEditUserInfo:hover {
    color: #314557;
    background-color: #FFFFFF;
    border: #314557 1px solid;
}

.lkTopUserMedal {
    width: 80px;
    height: 90px;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 8px;
}

.lkTopUserMedalIcon_Base {
    background-image: url(../../../public/svg/MedalBase.svg);
}

.lkTopUserMedalIcon_Bronze {
    background-image: url(../../../public/svg/MedalBronze.svg);
}

.lkTopUserMedalIcon_Silver {
    background-image: url(../../../public/svg/MedalSilver.svg);
}

.lkTopUserMedalIcon_Gold {
    background-image: url(../../../public/svg/MedalGold.svg);
}

.lkTopUserStatus_Base {
    color: #b1b1b1;
}

.lkTopUserStatus_Bronze {
    color: #9d5600;
}

.lkTopUserStatus_Silver {
    color: #a29f9e;
}

.lkTopUserStatus_Gold {
    color: #cba500;
}

.lkTopAccount {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lkTopAccount p {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    color: #314557;
}

.lkTopAccountMoney {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    color: #591E4A;
}

.lkButtonDeposit {
    margin-left: 15px;
    background: #314557;
    border: 1px solid #314557;
    border-radius: 35px;
    color: #FFFFFF;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    width: 160px;
    height: 45px;
    cursor: pointer;
    transition: 300ms;
}

.lkButtonDeposit:hover {
    color: #314557;
    background-color: #FFFFFF;
    border: #314557 1px solid;
}

.lkFooter {
    width: 100%;
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
}

.lkFooterButtons {
    max-width: 900px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.pickUpModal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.pickUpModal {
    background-color: white;
    padding: 20px;
    border: 2px solid #591e4a;
    border-radius: 5px;
    position: relative;
    width: 80%;
}

.pickUpModalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalButtons {
    max-width: 900px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 20px;
}

.uploadMopickUpModaldal h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #505050;
    text-align: center;
}